import { Result, Steps } from 'antd';
import { FC, useState, useEffect, useRef } from 'react';
import FormFirstStep from './steps/FormFirstStep';
import FormSecondStep from './steps/FormSecondStep';
import FormThirdStep from './steps/FormThirdStep';
import FormForthStep from './steps/FormForthStep';

import styles from './Form.module.css';
import IFormQuestion from '../../types/IFormQuestion';
import IFormFirstStep from '../../types/IFormFirstStep';
import { generateFormReports } from '../../api/form';

import toFormResponses from '../../utils/toFormResponses';
import Spinner from '../../components/spinner/Spinner';

interface FormPageProps {
  type: 'Personality' | 'Learning';
}

const { Step } = Steps;

interface FormData {
  firstStep: {
    email: string;
    fullName: string;
  };
  secondStep: IFormQuestion[];
  thirdStep: IFormQuestion[];
  forthStep: IFormQuestion[];
}

const FormPage: FC<FormPageProps> = ({ type }) => {
  const [current, setCurrent] = useState(0);
  const cachedFormData = localStorage.getItem('formData');
  const containerEl = useRef<HTMLDivElement>(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [formData, setFormData] = useState<FormData>(
    cachedFormData
      ? JSON.parse(cachedFormData)
      : {
          firstStep: { email: '', fullName: '' },
          secondStep: [],
          thirdStep: [],
          forthStep: [],
        },
  );

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    containerEl.current?.parentElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [current]);

  const updateFormData = (
    field: string,
    values: IFormFirstStep | IFormQuestion[],
  ) => {
    setFormData((formData) => ({
      ...formData,
      [field]: values,
    }));
  };

  const generateReports = () => {
    setShowSpinner(true);
    generateFormReports({
      email: formData.firstStep.email,
      userName: formData.firstStep.fullName,
      rate: Number(formData.forthStep[0].answer),
      isDATProgramme: type === 'Personality',
      completedOn: new Date(),
      responses: toFormResponses(formData.secondStep, formData.thirdStep),
    })
      .then((response) => {
        if (response === 'successfully') {
          setCurrent(current + 1);
          localStorage.setItem('formData', '');
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const steps = [
    {
      title: '',
      content: (
        <FormFirstStep
          values={formData.firstStep}
          onNext={(values) => {
            setCurrent(current + 1);
            updateFormData('firstStep', values);
          }}
          onChange={(values) => {
            updateFormData('firstStep', values);
          }}
        />
      ),
    },
    {
      title: '',
      content: (
        <FormSecondStep
          onNext={(values) => {
            updateFormData('secondStep', values);
            setCurrent(current + 1);
          }}
          onPrev={(values) => {
            updateFormData('secondStep', values);
            setCurrent(current - 1);
          }}
          onChange={(values) => {
            updateFormData('secondStep', values);
          }}
          values={formData.secondStep}
        />
      ),
    },
    {
      title: '',
      content: (
        <FormThirdStep
          onNext={(values) => {
            updateFormData('thirdStep', values);
            setCurrent(current + 1);
          }}
          onPrev={(values) => {
            updateFormData('thirdStep', values);
            setCurrent(current - 1);
          }}
          onChange={(values) => {
            updateFormData('thirdStep', values);
          }}
          values={formData.thirdStep}
        />
      ),
    },
    {
      title: '',
      content: (
        <FormForthStep
          onNext={(values) => {
            updateFormData('forthStep', values);
            generateReports();
          }}
          onPrev={(values) => {
            updateFormData('forthStep', values);
            setCurrent(current - 1);
          }}
          onChange={(values) => {
            updateFormData('forthStep', values);
          }}
          values={formData.forthStep}
        />
      ),
    },
    {
      title: '',
      content: (
        <Result
          style={{ width: '50%', margin: '2rem auto' }}
          status='success'
          title='Leadership Launchpad Thank you!'
          subTitle='Your Leadership Launchpad report will be emailed to you shortly. In
        the meantime, you might like to check out our latest research at'
          extra={[
            <a
              key='research-page-link'
              target='_blank'
              rel='noreferrer'
              href='https://horizon37.co.uk/research/'
            >
              https://horizon37.co.uk/research/
            </a>,
          ]}
        />
      ),
    },
  ];

  return (
    <Spinner show={showSpinner}>
      <div className={styles.pageContainer} ref={containerEl}>
        <Steps
          progressDot
          current={current}
          style={{ justifyContent: 'center' }}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={styles.stepContent}>{steps[current].content}</div>
      </div>
    </Spinner>
  );
};

export default FormPage;
