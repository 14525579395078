import axiosConnection from './baseApi';
import IUser from '../types/IUser';

interface UsersResponse {
  users: IUser[];
}

export const getUsers = () => {
  return axiosConnection
    .get<UsersResponse>('/users')
    .then(({ data, status }) => {
      if (status === 200) {
        return data.users;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};

export const setName = (userId: string, name: string) => {
  return axiosConnection
    .post<{ user: IUser }>('/users/' + userId, {
      name,
    })
    .then(({ data, status }) => {
      if (status === 200) {
        return data.user;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};
