import { Button, Card, Form, Rate, Space } from 'antd';

import { FC } from 'react';
import IFormQuestion from '../../../types/IFormQuestion';

interface FormForthStepProps {
  values: IFormQuestion[];
  onNext: (formStep: IFormQuestion[]) => void;
  onPrev: (formStep: IFormQuestion[]) => void;
  onChange: (formStep: IFormQuestion[]) => void;
}

const FormForthStep: FC<FormForthStepProps> = ({
  onChange,
  onNext,
  onPrev,
  values,
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
      layout='vertical'
      initialValues={Object.fromEntries(
        values.map(({ question, answer }) => [question, Number(answer || 0)]),
      )}
      onValuesChange={() => {
        onChange([
          {
            question: 'rate',
            answer: form.getFieldValue('star'),
          },
        ]);
      }}
    >
      <Card title='One last thing…!'>
        <p>How easy was it to use this Leadership Launchpad diagnostic tool?</p>
        <Form.Item
          label=''
          name='star'
          rules={[
            {
              required: true,
              message: 'Please rate',
            },
          ]}
          style={{ marginBottom: 0, textAlign: 'center' }}
        >
          <Rate />
        </Form.Item>
      </Card>
      <Space style={{ margin: 'auto' }}>
        <Button
          onClick={() => {
            onPrev([
              {
                question: 'rate',
                answer: form.getFieldValue('star'),
              },
            ]);
          }}
        >
          Previous
        </Button>
        <Form.Item shouldUpdate style={{ margin: 'auto' }}>
          {() => (
            <Button
              type='primary'
              htmlType='submit'
              disabled={
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
              onClick={() => {
                form.validateFields().then(() => {
                  onNext([
                    {
                      question: 'rate',
                      answer: form.getFieldValue('star'),
                    },
                  ]);
                });
              }}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Space>
    </Form>
  );
};

export default FormForthStep;
