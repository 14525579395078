import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { Cookie } from '../utils/cookie';

const poolData: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID as string,
};

export const cognitoLogin = (username: string, password: string) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const userPool = new CognitoUserPool(poolData);

  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise<{ user: CognitoUser | null; token: string }>(
    (resolve, reject) => {
      user.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();
          Cookie.setCookie('launchpadToken', accessToken, 30);

          resolve({
            user: userPool.getCurrentUser(),
            token: accessToken,
          });
        },
        onFailure: function (err) {
          reject(err.message || JSON.stringify(err));
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          delete userAttributes.email_verified;
          delete userAttributes.email;
          userAttributes.name = 'hey360';
          user.completeNewPasswordChallenge('=$L7M&vx', userAttributes, {
            onSuccess: (result) => {
              console.log({ result });
            },
            onFailure: (err) => {
              reject(err.message || JSON.stringify(err));
            },
          });
          // reject('New password is required');
        },
      });
    },
  );
};

export const cognitoLogout = () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  Cookie.clearCookie('launchpadToken');

  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const currentCognitoUser = () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  return cognitoUser;
};
