import { Form, Input, Button, message as AntMessage } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import './Login.css';
import { cognitoLogin } from '../../auth';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import Spinner from '../../components/spinner/Spinner';

export default function Login() {
  const history = useHistory();
  const [, setUser] = useAuth();
  const [form] = Form.useForm();
  const [showLoginSpinner, setLoginSpinner] = useState(false);

  return (
    <Spinner show={showLoginSpinner}>
      <div className='login-body'>
        <Form
          form={form}
          name='normal_login'
          className='login-form'
          onFinish={(values) => {
            setLoginSpinner(true);
            cognitoLogin(values.username, values.password)
              .then(({ user }) => {
                if (user) {
                  setUser(user);
                  AntMessage.success('User has logged in');
                  setLoginSpinner(false);
                  history.push('/');
                }
              })
              .catch((message) => {
                setLoginSpinner(false);
                AntMessage.error(message);
              });

            form.resetFields();
          }}
        >
          <img
            className='login-form__app-logo'
            src={process.env.PUBLIC_URL + '/logo.png'}
            alt='Leadership Launchpad'
          />
          <Form.Item
            name='username'
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              prefix={
                <UserOutlined
                  twoToneColor='#5693b6'
                  className='site-form-item-icon'
                />
              }
              placeholder='Username'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={
                <LockOutlined
                  twoToneColor='#5693b6'
                  className='site-form-item-icon'
                />
              }
              type='password'
              placeholder='Password'
            />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spinner>
  );
}
