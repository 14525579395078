import IFormQuestion from '../types/IFormQuestion';

const toFormResponses = (
  secondStepData: IFormQuestion[],
  thirdStepData: IFormQuestion[],
) => {
  const multipleAnswers = secondStepData.map(({ answer }) => {
    switch (answer) {
      case 'Strongly disagree':
        return 1;
      case 'Disagree':
        return 2;
      case 'Neutral':
        return 3;
      case 'Agree':
        return 4;
      case 'Strongly agree':
        return 5;
      default:
        return 1;
    }
  });
  const singleAnswers = thirdStepData.map(({ answer }) => Number(answer));

  return {
    O:
      8 +
      multipleAnswers[4] -
      multipleAnswers[9] +
      multipleAnswers[14] -
      multipleAnswers[19] +
      multipleAnswers[24] -
      multipleAnswers[29] +
      multipleAnswers[34] +
      multipleAnswers[39] +
      multipleAnswers[44] +
      multipleAnswers[49],
    C:
      14 +
      multipleAnswers[2] -
      multipleAnswers[7] +
      multipleAnswers[12] -
      multipleAnswers[17] +
      multipleAnswers[22] -
      multipleAnswers[27] +
      multipleAnswers[32] -
      multipleAnswers[37] +
      multipleAnswers[42] +
      multipleAnswers[47],
    E:
      20 +
      multipleAnswers[1] -
      multipleAnswers[5] +
      multipleAnswers[10] -
      multipleAnswers[15] +
      multipleAnswers[20] -
      multipleAnswers[25] +
      multipleAnswers[30] -
      multipleAnswers[35] +
      multipleAnswers[40] -
      multipleAnswers[45],
    A:
      14 -
      multipleAnswers[0] +
      multipleAnswers[6] -
      multipleAnswers[11] +
      multipleAnswers[16] -
      multipleAnswers[21] +
      multipleAnswers[26] -
      multipleAnswers[31] +
      multipleAnswers[36] +
      multipleAnswers[41] +
      multipleAnswers[46],
    N:
      38 -
      multipleAnswers[3] +
      multipleAnswers[8] -
      multipleAnswers[13] +
      multipleAnswers[18] -
      multipleAnswers[23] -
      multipleAnswers[28] -
      multipleAnswers[33] -
      multipleAnswers[38] -
      multipleAnswers[43] -
      multipleAnswers[48],
    xAxis:
      singleAnswers[0] +
      singleAnswers[3] +
      singleAnswers[7] +
      singleAnswers[8] +
      singleAnswers[9],
    yAxis:
      singleAnswers[1] +
      singleAnswers[2] +
      singleAnswers[4] +
      singleAnswers[5] +
      singleAnswers[6],
  };
};

export default toFormResponses;
