import InviteElement from '../types/IInviteElement';
import axiosConnection from './baseApi';

export const sendInvitationEmail = (emails: InviteElement[]) => {
  return axiosConnection
    .post<InviteElement[]>(`/invite`, { emails })
    .then(({ status }) => {
      if (status === 200) {
        return 'success';
      }

      return 'error';
    })
    .catch((err) => {
      console.error(err);
    });
};
