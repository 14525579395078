import Layout, { Content, Footer } from 'antd/lib/layout/layout';
import { Switch, Route, Router, Redirect, RouteProps } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Header from '../components/header/Header';
import useAuth from '../hooks/useAuth';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';
import Scores from '../pages/scores/Scores';
import Styles from '../pages/styles/Styles';
import Personalities from '../pages/personalities/Personalities';
import FormPage from '../pages/form/Form';

export const history = createBrowserHistory();

export default function Routes() {
  return (
    <Router history={history}>
      <Layout>
        <Header />
        <Content>
          <Switch>
            <ProtectedPage fallBack='/login' exact path='/'>
              <Home />
            </ProtectedPage>
            <ProtectedPage fallBack='/login' exact path='/styles'>
              <Styles />
            </ProtectedPage>
            <ProtectedPage fallBack='/login' exact path='/scores'>
              <Scores />
            </ProtectedPage>
            <ProtectedPage fallBack='/login' exact path='/personalities'>
              <Personalities />
            </ProtectedPage>
            <Route exact path='/login'>
              <Login />
            </Route>
            <Route exact path='/personality-type-form'>
              <FormPage type='Personality' />
            </Route>
            <Route exact path='/learning-style-form'>
              <FormPage type='Learning' />
            </Route>
            <Route path='*'>
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'right' }}>
          {'Copyright © '} {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Router>
  );
}

function ProtectedPage({
  children,
  fallBack,
  ...rest
}: { fallBack: string } & RouteProps) {
  const [user] = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: fallBack,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
