import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Layout, Button, message as AntMessage } from 'antd';
import { cognitoLogout } from '../../auth';
import useAuth from '../../hooks/useAuth';

import './Header.css';
import { useEffect, useState } from 'react';
import InviteUser from '../invite-user/InviteUser';

const AntHeader = Layout.Header;

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useAuth();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  const [hideActions, setHideActions] = useState(false);

  useEffect(() => {
    setHideActions(
      location.pathname === '/personality-type-form' ||
        location.pathname === '/learning-style-form',
    );
  }, [location]);

  return (
    <AntHeader className='ll-app-header'>
      <div
        className='ll-app-header__title'
        onClick={() => {
          if (!hideActions) {
            setCurrentPath('/');
            history.push('/');
          }
        }}
      >
        <img
          className='ll-app-header__title__icon'
          src={process.env.PUBLIC_URL + '/logo.png'}
          alt='Leadership Launchpad'
        />
        Leadership Launchpad
      </div>
      <Menu
        theme='dark'
        mode='horizontal'
        selectedKeys={[currentPath]}
        onSelect={({ key }) => {
          setCurrentPath(key as string);
          history.push(key as string);
        }}
        defaultSelectedKeys={[history.location.pathname]}
      >
        {user && !hideActions && (
          <>
            <Menu.Item key='/'>Home</Menu.Item>
            <Menu.Item key='/scores'>Scores</Menu.Item>
            <Menu.Item key='/styles'>Styles</Menu.Item>
            <Menu.Item key='/personalities'>Personalities</Menu.Item>
          </>
        )}
      </Menu>
      {!hideActions &&
        (!user ? (
          <Button
            type='primary'
            onClick={() => {
              history.push('login');
            }}
          >
            Login
          </Button>
        ) : (
          <>
            <InviteUser />
            <Button
              type='default'
              onClick={() => {
                cognitoLogout();
                setUser(null);
                AntMessage.success('User has logged out');
                history.push('login');
              }}
            >
              Logout
            </Button>
          </>
        ))}
    </AntHeader>
  );
};

export default Header;
