import axiosConnection from './baseApi';
import IStyle from '../types/IStyle';

interface StylesResponse {
  styles: IStyle[];
}

export const getStyles = () => {
  return axiosConnection
    .get<StylesResponse>('/styles')
    .then(({ data, status }) => {
      if (status === 200) {
        return data.styles;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};

export const setStyleDetails = (
  styleId: string,
  description: string,
  video: string,
  footerVideo: string,
) => {
  return axiosConnection
    .post<{ style: IStyle }>('/styles/' + styleId, {
      description,
      video,
      footerVideo,
    })
    .then(({ data, status }) => {
      if (status === 200) {
        return data.style;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};
