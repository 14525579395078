import { Button, Card, Form, Input } from 'antd';

import { FC } from 'react';
import IFormFirstStep from '../../../types/IFormFirstStep';

interface FirstStepProps {
  values: IFormFirstStep;
  onNext: (formStep: IFormFirstStep) => void;
  onChange: (formStep: IFormFirstStep) => void;
}

const FormFirstStep: FC<FirstStepProps> = ({ values, onChange, onNext }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={values}
      layout='vertical'
      onValuesChange={(_, allValues) => {
        if (!!form.getFieldsError().filter(({ errors }) => errors.length)) {
          onChange(allValues);
        }
      }}
      scrollToFirstError
      style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
    >
      <Card title='Leadership Launchpad'>
        <p>
          The Leadership Launchpad helps you identify your preferences and
          optimal conditions for learning and growing as a leader.
        </p>
        <p>
          The form should take less than 10 minutes to complete. You will
          receive a comprehensive report shortly after submitting.
        </p>
      </Card>
      <Card>
        <Form.Item
          label={<strong>Email</strong>}
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter your email',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input placeholder='Your email address'></Input>
        </Form.Item>
      </Card>
      <Card>
        <Form.Item
          style={{ marginBottom: 0 }}
          name='fullName'
          label={
            <strong>What is your full name? (First name and last name)</strong>
          }
          rules={[{ required: true, message: 'Please enter your full name' }]}
        >
          <Input placeholder='Your full name'></Input>
        </Form.Item>
      </Card>
      <Form.Item shouldUpdate style={{ margin: 'auto' }}>
        {() => (
          <Button
            type='primary'
            htmlType='submit'
            disabled={
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
            onClick={() => {
              form.validateFields().then(() => {
                onNext({
                  email: form.getFieldValue('email'),
                  fullName: form.getFieldValue('fullName'),
                });
              });
            }}
          >
            Next
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default FormFirstStep;
