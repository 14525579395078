import { Spin } from 'antd';
import { FunctionComponent } from 'react';

import './Spinner.css';

interface SpinnerProps {
  show: boolean;
}

const Spinner: FunctionComponent<SpinnerProps> = ({ show, children }) => {
  if (show) {
    return <Spin>{children}</Spin>;
  }

  return <>{children}</>;
};

export default Spinner;
