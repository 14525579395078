import {
  Button,
  Checkbox,
  Drawer,
  Input,
  message as AntMessage,
  Select,
  Table,
  Tag,
} from 'antd';
import { useState } from 'react';
import validator from 'validator';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  UserAddOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { generateRandomColor } from '../../utils/color';

import InviteElement from '../../types/IInviteElement';

import styles from './InviteUser.module.css';
import { RefSelectProps } from 'antd/lib/select';
import { sendInvitationEmail } from '../../api/inviteUser';

import Spinner from '../spinner/Spinner';

const InviteUser = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [emails, setEmails] = useState<InviteElement[]>([]);
  const [showSpinner, setSpinner] = useState(false);

  let selectRef: RefSelectProps | null;

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setEmails([]);
  };

  return (
    <>
      <Button
        type='primary'
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        Invite <UserAddOutlined />
      </Button>
      <Drawer
        title='Invite User(s)'
        placement='right'
        onClose={onCloseDrawer}
        contentWrapperStyle={{ width: '100%' }}
        visible={isDrawerOpen}
      >
        <Spinner show={showSpinner}>
          <div className={styles['ll-invite-user-container']}>
            <Select
              mode='tags'
              style={{ width: '100%' }}
              placeholder='Type user emails'
              ref={(ref) => (selectRef = ref)}
              onSelect={(email: string) => {
                setEmails((emails) => [
                  ...emails,
                  {
                    email,
                    color: generateRandomColor(),
                    name: '',
                    isDATProgramme: false,
                  },
                ]);

                selectRef && selectRef.blur();
              }}
              onDeselect={(deletedEmail: string) =>
                setEmails((emails) =>
                  emails.filter(({ email }) => email !== deletedEmail),
                )
              }
            ></Select>
            {emails && (
              <div className={styles['ll-invite-user-emails']}>
                <Table
                  dataSource={emails.map(({ email, ...rest }) => ({
                    ...rest,
                    email,
                    key: email,
                    id: email,
                  }))}
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      key: 'name',
                      render: (_, record: InviteElement) => {
                        return (
                          <Input
                            defaultValue={record.name}
                            onChange={(e) =>
                              setEmails((prevEmails) =>
                                prevEmails.map((prevEmail) =>
                                  prevEmail.email === record.email
                                    ? {
                                        ...prevEmail,
                                        name: e.target.value,
                                      }
                                    : prevEmail,
                                ),
                              )
                            }
                          ></Input>
                        );
                      },
                      width: '20%',
                    },
                    {
                      title: 'Email',
                      dataIndex: 'email',
                      key: 'email',
                      width: '40%',
                      render: (_, record: InviteElement) => {
                        return <Tag color={record.color}>{record.email}</Tag>;
                      },
                    },
                    {
                      title: 'Is DAT?',
                      dataIndex: 'isDATProgramme',
                      key: 'isDATProgramme',
                      render: (_, record: InviteElement) => {
                        return (
                          <Checkbox
                            defaultChecked={record.isDATProgramme}
                            onChange={(e) =>
                              setEmails((prevEmails) =>
                                prevEmails.map((prevEmail) =>
                                  prevEmail.email === record.email
                                    ? {
                                        ...prevEmail,
                                        isDATProgramme: e.target.checked,
                                      }
                                    : prevEmail,
                                ),
                              )
                            }
                          />
                        );
                      },
                      width: 100,
                    },
                    {
                      title: 'Valid Email',
                      dataIndex: 'isValidEmail',
                      key: 'isValidEmail',
                      width: '30%',
                      render: (_, record: InviteElement) => {
                        return (
                          (validator.isEmail(record.email) && (
                            <CheckCircleFilled
                              style={{ color: 'green', fontSize: 18 }}
                            />
                          )) || (
                            <CloseCircleFilled
                              style={{ color: 'red', fontSize: 18 }}
                            />
                          )
                        );
                      },
                    },
                  ]}
                  size='large'
                  scroll={{ y: '50%' }}
                  pagination={{
                    defaultPageSize: 50,
                    position: ['bottomCenter'],
                  }}
                />
              </div>
            )}
            <Button
              className={
                styles['ll-invite-user-container__send-invitation-button']
              }
              type='primary'
              onClick={() => {
                setSpinner(true);

                sendInvitationEmail(
                  emails.map(({ email, isDATProgramme, name }) => ({
                    email,
                    name,
                    isDATProgramme,
                  })),
                ).then((result) => {
                  setSpinner(true);

                  if (result === 'success') {
                    AntMessage.success('Emails have been sent successfully');
                  } else {
                    AntMessage.error('Server Error');
                  }

                  setIsDrawerOpen(false);
                  setSpinner(false);
                });
              }}
              disabled={
                !emails.length ||
                emails.some(
                  ({ email, name }) =>
                    !validator.isEmail(email) || !name.trim(),
                )
              }
            >
              Send <FormOutlined />
            </Button>
          </div>
        </Spinner>
      </Drawer>
    </>
  );
};

export default InviteUser;
