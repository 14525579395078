import { Button, Card, Form, Radio, Space, Typography } from 'antd';

import { FC } from 'react';
import IFormQuestion from '../../../types/IFormQuestion';

import { singleChoiceQuestions } from '../../../utils/formQuestions';

interface FormThirdStepProps {
  values: IFormQuestion[];
  onNext: (formStep: IFormQuestion[]) => void;
  onPrev: (formStep: IFormQuestion[]) => void;
  onChange: (formStep: IFormQuestion[]) => void;
}

const { Text } = Typography;

const FormThirdStep: FC<FormThirdStepProps> = ({
  onChange,
  onNext,
  onPrev,
  values,
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
      layout='vertical'
      initialValues={Object.fromEntries(
        values.map(({ question, answer }) => [question, Number(answer || 0)]),
      )}
      onValuesChange={() => {
        if (!!form.getFieldsError().filter(({ errors }) => errors.length)) {
          onChange(
            singleChoiceQuestions.map(({ leftLabel, rightLabel }) => ({
              question: leftLabel + '-' + rightLabel,
              answer: form.getFieldValue(leftLabel + '-' + rightLabel),
            })),
          );
        }
      }}
    >
      <Card title='Learning Style'>
        <p>
          This section helps determine your learning style and preferences, and
          how this relates to your personality traits.
        </p>
      </Card>
      {singleChoiceQuestions.map(({ leftLabel, rightLabel, answers }) => (
        <Card key={leftLabel + '-' + rightLabel}>
          <strong>
            <Text type='danger'>*</Text> I prefer
          </strong>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-around',
              marginTop: '24px',
            }}
          >
            <div>{leftLabel}</div>
            <Form.Item
              label=''
              name={leftLabel + '-' + rightLabel}
              rules={[
                {
                  required: true,
                  message: 'Please answer',
                },
              ]}
              style={{ marginBottom: 0, textAlign: 'center' }}
            >
              <Radio.Group>
                <Space>
                  {answers.map((answer) => (
                    <Radio
                      key={answer}
                      value={answer}
                      style={{
                        flexDirection: 'column-reverse',
                        alignItems: 'center',
                      }}
                    >
                      {answer}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
            <div>{rightLabel}</div>
          </div>
        </Card>
      ))}
      <Space style={{ margin: 'auto' }}>
        <Button
          onClick={() => {
            onPrev(
              singleChoiceQuestions.map(({ leftLabel, rightLabel }) => ({
                question: leftLabel + '-' + rightLabel,
                answer: form.getFieldValue(leftLabel + '-' + rightLabel),
              })),
            );
          }}
        >
          Previous
        </Button>
        <Form.Item shouldUpdate style={{ margin: 'auto' }}>
          {() => (
            <Button
              type='primary'
              htmlType='submit'
              disabled={
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
              onClick={() => {
                form.validateFields().then(() => {
                  onNext(
                    singleChoiceQuestions.map(({ leftLabel, rightLabel }) => ({
                      question: leftLabel + '-' + rightLabel,
                      answer: form.getFieldValue(leftLabel + '-' + rightLabel),
                    })),
                  );
                });
              }}
            >
              Next
            </Button>
          )}
        </Form.Item>
      </Space>
    </Form>
  );
};

export default FormThirdStep;
