import {
  Button,
  Input,
  message as AntMessage,
  Modal,
  Table,
  Divider,
} from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import IStyle from '../../types/IStyle';
import { getStyles, setStyleDetails } from '../../api/style';

import './Styles.css';

const Styles = () => {
  let [styles, setStyles] = useState<IStyle[]>([]);
  let [isModalVisible, setModalVisible] = useState(false);
  let [selectedStyle, setSelectedStyle] = useState<IStyle | null>(null);
  let [selectedDescription, setSelectedDescription] = useState('');
  let [selectedVideo, setSelectedVideo] = useState('');
  let [selectedFooterVideo, setSelectedFooterVideo] = useState('');

  useEffect(() => {
    fetchStyles();
  }, []);

  const fetchStyles = () => {
    getStyles().then((styles) => {
      setStyles(styles as IStyle[]);
    });
  };

  const refresh = () => {
    setSelectedDescription('');
    setSelectedVideo('');
    setSelectedFooterVideo('');
    setSelectedStyle(null);
    setModalVisible(false);
  };

  return (
    <div className='page-container'>
      <Table
        dataSource={styles.map(({ id, ...rest }) => ({
          ...rest,
          key: id,
          id,
        }))}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
          },
          {
            title: 'Video',
            dataIndex: 'video',
            key: 'video',
            width: '15%',
          },
          {
            title: 'FooterVideo',
            dataIndex: 'footerVideo',
            key: 'footerVideo',
            width: '15%',
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button
                type='link'
                shape='circle'
                icon={<EditTwoTone twoToneColor='#5693b6' size={30} />}
                onClick={() => {
                  setModalVisible(true);
                  setSelectedStyle(record);
                  setSelectedDescription(record.description);
                  setSelectedVideo(record.video);
                  setSelectedFooterVideo(record.footerVideo);
                }}
              />
            ),
            width: 80,
          },
        ]}
        size='large'
        scroll={{ y: '50%' }}
        pagination={{ defaultPageSize: 50, position: ['bottomCenter'] }}
      />
      <Modal
        title='Set Description'
        visible={isModalVisible}
        onOk={() => {
          setStyleDetails(
            selectedStyle!.id,
            selectedDescription,
            selectedVideo,
            selectedFooterVideo,
          ).then(() => {
            AntMessage.success('Description is updated successfully');
            fetchStyles();
            refresh();
          });
        }}
        onCancel={() => {
          refresh();
        }}
      >
        <Input.TextArea
          value={selectedDescription}
          maxLength={2000}
          rows={7}
          placeholder='Enter description'
          onChange={(e) => setSelectedDescription(e.target.value)}
        />
        <Divider />
        <Input
          value={selectedVideo}
          maxLength={2000}
          placeholder='Enter video url'
          onChange={(e) => setSelectedVideo(e.target.value)}
        />
        <Divider />
        <Input
          value={selectedFooterVideo}
          maxLength={2000}
          placeholder='Enter footer video url'
          onChange={(e) => setSelectedFooterVideo(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Styles;
