import { Button, Input, message as AntMessage, Modal, Table } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useLocation } from 'react-router';

import { getUsers, setName } from '../../api/user';

import IUser from '../../types/IUser';

import './Home.css';

const Home = () => {
  let [users, setUsers] = useState<IUser[]>([]);
  let [isModalVisible, setModalVisible] = useState(false);
  let [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  let [selectedUserName, setSelectedUserName] = useState('');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [searchText, setSearchText] = useState(searchParams.get('name') || '');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    getUsers().then((users) => {
      setUsers(users as IUser[]);
    });
  };

  const refresh = () => {
    setSelectedUserName('');
    setSelectedUser(null);
    setModalVisible(false);
  };

  return (
    <div className='page-container'>
      <Input
        allowClear
        style={{
          marginBottom: '1rem',
          width: '33%',
          float: 'right',
          minWidth: '300px',
        }}
        defaultValue={searchText}
        placeholder='Search by user name or email'
        onChange={(e) => setSearchText(e.target.value)}
      ></Input>
      <Table
        dataSource={users
          .filter(({ name, email }) => {
            return searchText === '' || (!name && searchText === '')
              ? true
              : name?.toLowerCase().includes(searchText.toLowerCase()) ||
                  email?.toLowerCase().includes(searchText.toLowerCase());
          })
          .map(({ id, ...rest }) => ({
            ...rest,
            key: id,
            id,
          }))}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (text) => (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ),
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '40%',
            render: (text) => (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ),
          },
          {
            title: 'Completed On',
            dataIndex: 'completedOn',
            key: 'completedOn',
            render: (_, record: IUser) => {
              return dayjs(record.completedOn).format('DD/MM/YYYY HH:mm');
            },
            width: 180,
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Button
                type='link'
                shape='circle'
                icon={<EditTwoTone twoToneColor='#5693b6' size={30} />}
                onClick={() => {
                  setModalVisible(true);
                  setSelectedUser(record);
                }}
              />
            ),
            width: 80,
          },
        ]}
        size='large'
        scroll={{ y: '50%' }}
        pagination={{ defaultPageSize: 50, position: ['bottomCenter'] }}
      />
      <Modal
        title='Set User Name'
        visible={isModalVisible}
        onOk={() => {
          setName(selectedUser!.id, selectedUserName).then(() => {
            AntMessage.success('User is updated successfully');
            fetchUsers();
            refresh();
          });
        }}
        onCancel={() => {
          refresh();
        }}
      >
        <Input
          value={selectedUserName}
          placeholder='Enter a username'
          onChange={(e) => setSelectedUserName(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Home;
