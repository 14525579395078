import {
  Button,
  message as AntMessage,
  Input,
  Space,
  Table,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { FilePdfTwoTone, MailTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useLocation } from 'react-router-dom';

import IScore from '../../types/IScore';
import { getPDF, getScores, sendEmail } from '../../api/score';

import './Scores.css';

const { Text } = Typography;

const Scores = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let [scores, setScores] = useState<IScore[]>([]);
  const [searchText, setSearchText] = useState(searchParams.get('name') || '');

  useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = () => {
    getScores().then((scores) => {
      setScores(scores as IScore[]);
    });
  };

  return (
    <div className='page-container'>
      <Input
        allowClear
        style={{ marginBottom: '1rem', width: '33%', float: 'right' }}
        placeholder='Search by user name'
        onChange={(e) => setSearchText(e.target.value)}
        defaultValue={searchText}
      ></Input>
      <Table
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem 2rem',
              }}
            >
              <Space direction='vertical' size={30}>
                <Space>
                  <Text style={{ marginRight: '0.8rem' }}>
                    Learning Style Reports
                  </Text>
                  <Button
                    type='link'
                    shape='circle'
                    icon={<FilePdfTwoTone twoToneColor='#5693b6' size={30} />}
                    onClick={() => {
                      getPDF({ userId: record.UserId, isDATProgramme: false });
                    }}
                  />
                  <Button
                    type='link'
                    shape='circle'
                    icon={<MailTwoTone twoToneColor='#5693b6' size={30} />}
                    onClick={() => {
                      sendEmail({
                        userId: record.UserId,
                        isDATProgramme: false,
                      }).then((status) => {
                        if (status === 'success') {
                          AntMessage.success(
                            'The email has been sent successfully',
                          );
                        }
                      });
                    }}
                  />
                </Space>
                <Space align='center'>
                  <Text>Personality Type Reports</Text>
                  <Button
                    type='link'
                    shape='circle'
                    icon={<FilePdfTwoTone twoToneColor='#5693b6' size={30} />}
                    onClick={() => {
                      getPDF({ userId: record.UserId, isDATProgramme: true });
                    }}
                  />
                  <Button
                    type='link'
                    shape='circle'
                    icon={<MailTwoTone twoToneColor='#5693b6' size={30} />}
                    onClick={() => {
                      sendEmail({
                        userId: record.UserId,
                        isDATProgramme: true,
                      }).then((status) => {
                        if (status === 'success') {
                          AntMessage.success(
                            'The email has been sent successfully',
                          );
                        }
                      });
                    }}
                  />
                </Space>
              </Space>
            </div>
          ),
        }}
        style={{ fontSize: '14px' }}
        dataSource={scores
          .filter(({ userName }) => {
            return searchText === '' || (!userName && searchText === '')
              ? true
              : userName?.toLowerCase().includes(searchText.toLowerCase());
          })
          .map(({ id, ...rest }) => ({
            ...rest,
            key: id,
            id,
          }))}
        columns={[
          {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            render: (text) => (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ),
          },
          {
            align: 'center',
            title: 'O',
            dataIndex: 'O',
            key: 'O',
            width: 50,
          },
          {
            align: 'center',
            title: 'C',
            dataIndex: 'C',
            key: 'C',
            width: 50,
          },
          {
            align: 'center',
            title: 'E',
            dataIndex: 'E',
            key: 'E',
            width: 50,
          },
          {
            align: 'center',
            title: 'A',
            dataIndex: 'A',
            key: 'A',
            width: 50,
          },
          {
            align: 'center',
            title: 'N',
            dataIndex: 'N',
            key: 'N',
            width: 50,
          },
          {
            align: 'center',
            dataIndex: 'xAxis',
            key: 'xAxis',
            width: 130,
            title: 'Isolated/Social',
          },
          {
            align: 'center',
            dataIndex: 'yAxis',
            key: 'yAxis',
            width: 190,
            title: 'Theroetical/Experiential',
          },
          {
            align: 'center',
            dataIndex: 'rate',
            key: 'rate',
            width: 70,
            title: 'Rate',
          },
        ]}
        size='large'
        scroll={{ y: '50%' }}
        pagination={{ defaultPageSize: 50, position: ['bottomCenter'] }}
      />
    </div>
  );
};

export default Scores;
