const multipleChoiceAnswers = [
  'Strongly disagree',
  'Disagree',
  'Neutral',
  'Agree',
  'Strongly agree',
];

const singleChoiceAnswers = [1, 2];

export const multipleChoiceQuestions = [
  {
    type: 'multipleChoice',
    question: 'I feel little concern for others',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I am the life and soul of the party',
    answers: multipleChoiceAnswers,
  },
  { question: 'I am always prepared', answers: multipleChoiceAnswers },
  { question: 'I get stressed out easily', answers: multipleChoiceAnswers },
  { question: 'I have a rich vocabulary', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: "I don't like to talk a lot",
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I am interested in other people',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I leave my belongings around',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I am relaxed most of the time',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I have difficulty understanding abstract ideas',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I feel comfortable around people',
    answers: multipleChoiceAnswers,
  },
  { question: 'I insult people', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I pay attention to details',
    answers: multipleChoiceAnswers,
  },
  { question: 'I worry about things', answers: multipleChoiceAnswers },
  { question: 'I have a vivd imagination', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I like to stay in the background',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: "I sympathise with others' feelings",
    answers: multipleChoiceAnswers,
  },
  { question: 'I make a mess of things', answers: multipleChoiceAnswers },
  { question: 'I seldom feel down', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I am not interested in abstract ideas',
    answers: multipleChoiceAnswers,
  },
  { question: 'I start conversations', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: "I am not interested in other people's problems",
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I get small tasks done straight away',
    answers: multipleChoiceAnswers,
  },
  { question: 'I am easily disturbed', answers: multipleChoiceAnswers },
  { question: 'I have excellent ideas', answers: multipleChoiceAnswers },
  { question: 'I have little to say', answers: multipleChoiceAnswers },
  { question: 'I have a soft heart', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I often forget to put things back in their proper place',
    answers: multipleChoiceAnswers,
  },
  { question: 'I get upset easily', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I do not have a good imagination',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I talk to a lot of different people around the company',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I am not really interested in my colleagues',
    answers: multipleChoiceAnswers,
  },
  { question: 'I like order', answers: multipleChoiceAnswers },
  { question: 'My mood changes a lot', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I am quick to understand things',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: "I don't like to draw attention to myself",
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I take time out to help my colleagues',
    answers: multipleChoiceAnswers,
  },
  { question: 'I shirk my duties', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I have frequent mood swings',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I use a wide vocabulary to express myself',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: "I don't mind being the centre of attention",
    answers: multipleChoiceAnswers,
  },
  { question: "I feel others' emotions", answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I like to follow a schedule',
    answers: multipleChoiceAnswers,
  },
  { question: 'I get irritated easily', answers: multipleChoiceAnswers },
  {
    type: 'multipleChoice',
    question: 'I spend time reflecting on things',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I am quiet around new people / new employees',
    answers: multipleChoiceAnswers,
  },
  {
    type: 'multipleChoice',
    question: 'I make people feel at ease',
    answers: multipleChoiceAnswers,
  },
  { question: 'I am exacting in my work', answers: multipleChoiceAnswers },
  { question: 'I often feel sad or down', answers: multipleChoiceAnswers },
  { question: 'I am full of ideas', answers: multipleChoiceAnswers },
];

export const singleChoiceQuestions = [
  {
    type: 'singleChoice',
    leftLabel: 'Working independently',
    rightLabel: 'Working collaboratively',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'To think',
    rightLabel: 'To do',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'To read',
    rightLabel: 'To create',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'Contemplation',
    rightLabel: 'Discussing ideas',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'To research thoroughly',
    rightLabel: 'To research a little',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'To plan',
    rightLabel: 'To act',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'To learn by studying',
    rightLabel: 'To learn by doing',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'Individual tasks',
    rightLabel: 'Group tasks',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'Thinking premeditatively',
    rightLabel: 'Thinking on my feet',
    answers: singleChoiceAnswers,
  },
  {
    type: 'singleChoice',
    leftLabel: 'Learning by myself',
    rightLabel: 'Learning with others',
    answers: singleChoiceAnswers,
  },
];
