import { Button, Card, Form, Radio, Space } from 'antd';

import { FC } from 'react';
import IFormQuestion from '../../../types/IFormQuestion';

import { multipleChoiceQuestions } from '../../../utils/formQuestions';

interface FormSecondStepProps {
  values: IFormQuestion[];
  onNext: (formStep: IFormQuestion[]) => void;
  onPrev: (formStep: IFormQuestion[]) => void;
  onChange: (formStep: IFormQuestion[]) => void;
}

const FormSecondStep: FC<FormSecondStepProps> = ({
  values,
  onChange,
  onNext,
  onPrev,
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
      layout='vertical'
      onValuesChange={() => {
        if (!!form.getFieldsError().filter(({ errors }) => errors.length)) {
          onChange(
            multipleChoiceQuestions.map(({ question }) => ({
              question,
              answer: form.getFieldValue(question),
            })),
          );
        }
      }}
      initialValues={Object.fromEntries(
        values.map(({ question, answer }) => [question, answer]),
      )}
    >
      <Card
        title='To what extent do you agree with the following statements?'
        bodyStyle={{ padding: 0 }}
      ></Card>
      {multipleChoiceQuestions.map(({ question, answers }) => (
        <Card key={question}>
          <Form.Item
            label={<strong>{question}</strong>}
            name={question}
            rules={[
              {
                required: true,
                message: 'Please answer',
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Radio.Group>
              <Space direction='vertical'>
                {answers.map((answer) => (
                  <Radio key={answer} value={answer}>
                    {answer}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Card>
      ))}
      <Space style={{ margin: 'auto' }}>
        <Button
          onClick={() => {
            onPrev(
              multipleChoiceQuestions.map(({ question }) => ({
                question,
                answer: form.getFieldValue(question),
              })),
            );
          }}
        >
          Previous
        </Button>
        <Form.Item shouldUpdate style={{ margin: 'auto' }}>
          {() => (
            <Button
              type='primary'
              htmlType='submit'
              disabled={
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
              onClick={() => {
                form.validateFields().then(() => {
                  onNext(
                    multipleChoiceQuestions.map(({ question }) => ({
                      question,
                      answer: form.getFieldValue(question),
                    })),
                  );
                });
              }}
            >
              Next
            </Button>
          )}
        </Form.Item>
      </Space>
    </Form>
  );
};

export default FormSecondStep;
