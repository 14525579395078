import {
  Button,
  Form,
  FormInstance,
  Input,
  message as AntMessage,
  Tabs,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import './Personalities.css';

import { getPersonalities, updatePersonality } from '../../api/personality';

import IPersonality from '../../types/IPersonality';

import FormListItem from '../../components/form-list-item/FormListItem';
import Spinner from '../../components/spinner/Spinner';

const { TabPane } = Tabs;

const Personalities = () => {
  const [personalities, setPersonalities] = useState<IPersonality[]>([]);
  const formRef = useRef<FormInstance>(null);
  const [showSpinner, setSpinner] = useState(false);

  useEffect(() => {
    fetchStyles();
  }, []);

  const fetchStyles = () => {
    setSpinner(true);
    getPersonalities()
      .then((result) => {
        setPersonalities(result as IPersonality[]);
      })
      .finally(() => setSpinner(false));
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinish = (id: string, values: IPersonality) => {
    setSpinner(true);
    updatePersonality(id, values).finally(() => {
      setSpinner(false);
      AntMessage.success('Updated successfully');
      fetchStyles();
    });
  };

  const onReset = () => {
    formRef.current!.resetFields();
  };

  return (
    <div className='page-container'>
      <Tabs onChange={() => {}}>
        {personalities.map(
          ({
            id,
            name,
            descriptor,
            whoYouAre = [''],
            howAddValue = [''],
            howApplySkills,
            pitfallDescriptor,
            pitfalls = [''],
            questions = [''],
            workshops = [''],
          }) => (
            <TabPane tab={name} key={id}>
              <Spinner show={showSpinner}>
                <Form
                  ref={formRef}
                  name='control-ref'
                  onFinish={(values) => onFinish(id, values)}
                  layout='vertical'
                  initialValues={{
                    descriptor,
                    whoYouAre,
                    howAddValue,
                    howApplySkills,
                    pitfallDescriptor,
                    pitfalls,
                    questions,
                    workshops,
                  }}
                >
                  <Form.Item name='descriptor' label='Descriptor'>
                    <Input.TextArea />
                  </Form.Item>
                  <FormListItem
                    name='whoYouAre'
                    placeholder='Who You Are Item'
                    label='Who You Are'
                  />
                  <FormListItem
                    name='howAddValue'
                    placeholder='How Add Value Item'
                    label='How Add Value'
                  />
                  <FormListItem
                    name='pitfalls'
                    placeholder='Pitfall Item'
                    label='Pitfalls'
                  />
                  <Form.Item
                    name='pitfallDescriptor'
                    label='Pitfall Descriptor'
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item name='howApplySkills' label='How Apply Skills'>
                    <Input.TextArea />
                  </Form.Item>
                  <FormListItem
                    name='workshops'
                    placeholder='Workshop Item'
                    label='Workshops'
                  />
                  <FormListItem
                    name='questions'
                    placeholder='Question'
                    label='Questions'
                  />
                  <Form.Item {...tailLayout}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{ marginRight: '1rem' }}
                    >
                      Update
                    </Button>
                    <Button htmlType='button' onClick={onReset}>
                      Reset
                    </Button>
                  </Form.Item>
                </Form>
              </Spinner>
            </TabPane>
          ),
        )}
      </Tabs>
    </div>
  );
};

export default Personalities;
