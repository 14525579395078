import { message } from 'antd';
import axios from 'axios';
import { resetUser } from '../App';
import { Cookie } from '../utils/cookie';

const axiosConnection = axios.create({
  baseURL: 'https://api.launchpad.horizon37.co.uk',
});

axiosConnection.interceptors.request.use((config) => {
  config.headers['Authorization'] = Cookie.getCookie('launchpadToken');
  return config;
});

axiosConnection.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.toString() === 'Error: Request failed with status code 401' ||
      error.toString() === 'Error: Request failed with status code 500'
    ) {
      message.error('Unauthorized access. You need to login!');
      resetUser();
    }

    return Promise.reject(error);
  },
);

export default axiosConnection;
