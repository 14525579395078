import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { FunctionComponent } from 'react';

interface FormListItemProps {
  name: string;
  label: string;
  placeholder: string;
}

const FormListItem: FunctionComponent<FormListItemProps> = ({
  name: itemName,
  label,
  placeholder,
}) => {
  return (
    <Form.List name={itemName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              label={index === 0 ? label : ''}
              required={false}
              key={field.key}
              style={{ marginBottom: '0' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '24px',
                }}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                >
                  <Input
                    placeholder={placeholder}
                    style={{ flex: 1, paddingRight: '12px' }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className='dynamic-delete-button'
                    onClick={() => remove(field.name)}
                    style={{
                      marginBottom: '24px',
                      marginLeft: '12px',
                    }}
                  />
                ) : null}
              </div>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type='primary'
              onClick={() => add()}
              icon={<PlusOutlined />}
              style={{ marginLeft: '24px' }}
            >
              Add item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default FormListItem;
