import Routes, { history } from './routes';

import { AuthContext } from './contexts/authContext';
import { cognitoLogout, currentCognitoUser } from './auth';

import './App.css';
import { useState } from 'react';

const currentUser = currentCognitoUser();

export let resetUser = () => {};

function App() {
  const [user, setUser] = useState(currentUser);

  resetUser = () => {
    cognitoLogout();
    setUser(null);
    history.push('login');
  };

  return (
    <div className='ll-app'>
      <AuthContext.Provider value={[user, setUser]}>
        <Routes />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
