import axiosConnection from './baseApi';
import IScore from '../types/IScore';

interface ScoresResponse {
  scores: IScore[];
}

export const getScores = () => {
  return axiosConnection
    .get<ScoresResponse>('/scores')
    .then(({ data, status }) => {
      if (status === 200) {
        return data.scores;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};

export const getPDF = ({
  userId,
  isDATProgramme,
}: {
  userId: string;
  isDATProgramme: boolean;
}) => {
  axiosConnection
    .get(`/report/${userId}/pdf`, {
      method: 'GET',
      responseType: 'blob',
      params: { isDATProgramme },
    })
    .then(({ data, status }) => {
      if (status === 200) {
        const file = new Blob([data], { type: 'application/pdf' });

        const fileUrl = URL.createObjectURL(file);

        window.open(fileUrl);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const sendEmail = ({
  userId,
  isDATProgramme,
}: {
  userId: string;
  isDATProgramme: boolean;
}) => {
  return axiosConnection
    .get(`/report/${userId}/sendEmail`, { params: { isDATProgramme } })
    .then(({ status }) => {
      if (status === 200) {
        return 'success';
      }

      return 'error';
    })
    .catch((err) => {
      console.error(err);
    });
};
