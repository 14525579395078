import axiosConnection from './baseApi';
import IPersonality from '../types/IPersonality';

interface PersonalitiesResponse {
  personalities: IPersonality[];
}

export const getPersonalities = () => {
  return axiosConnection
    .get<PersonalitiesResponse>('/personalities')
    .then(({ data, status }) => {
      if (status === 200) {
        return data.personalities;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};

export const updatePersonality = (id: string, personality: IPersonality) => {
  return axiosConnection
    .post<{ personality: IPersonality }>('/personalities/' + id, personality)
    .then(({ data, status }) => {
      if (status === 200) {
        return data.personality;
      }

      return [];
    })
    .catch((err) => {
      console.error(err);

      return [];
    });
};
