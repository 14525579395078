import axiosConnection from './baseApi';

interface GenerateReportParams {
  email: string;
  completedOn: Date;
  userName: string;
  rate: number;
  responses: {
    O: number;
    C: number;
    E: number;
    A: number;
    N: number;
    xAxis: number;
    yAxis: number;
  };
  isDATProgramme: boolean;
}

export const generateFormReports = ({
  email,
  completedOn,
  userName,
  rate,
  responses: { O, C, E, A, N, xAxis, yAxis },
  isDATProgramme,
}: GenerateReportParams) => {
  return axiosConnection
    .post<string>('/form', {
      completedOn,
      email,
      isDATProgramme,
      rate,
      responses: { O, C, E, A, N, xAxis, yAxis },
      userName,
    })
    .then(({ status }) => {
      if (status === 200) {
        return 'successfully';
      }

      return '';
    })
    .catch((err) => {
      console.error(err);

      return '';
    });
};
